import React, { Component } from 'react'
import { Link } from "gatsby"
import Header from "../components/Header"
import ProjectHeader from "../components/ProjectHeader"
import Footer from "../components/Footer"
import '../css/project.css'
import Fade from 'react-reveal/Fade';
import SmallPic from '../components/SmallPic'
import { Helmet } from 'react-helmet'

class Facebook extends Component {
    render() {
      return (
        <div>
        <Header />
        <Fade>
        <div className="ProjectContainer">
        <ProjectHeader
            title="Facebook"
            description="Product Design Internship"
            timeline="Summer 2019"
            image={require('../images/fb.png')}
        />
        <h6>Background</h6>
        <h2>
            At Facebook, I designed on the Local Services team; our product empowered small to medium-sized, service-based business owners to intake service bookings online.
            </h2>
        <p>
            
            My team owned a very special part of Facebook Business Pages. We were responsible for delivering products and experiences that helped local businesses gain business through online bookings. Our user base consisted of businesses spanning industries such as lifestyle and beauty, health and wellness, home maintenance and repair, and animal care.  Our product also served sole proprietors and individuals specializing in repairs, education, elderly care, and more.
        </p>        
            <h6>Projects</h6>
            <h2>
            My work explored the possible ways our product could be integrated into existing consumer-facing entrypoints.
            </h2>
            <p>
            I worked on a number of projects that touched on how the Services product could be embedded in Messenger, the new Local tab, and the Saved tab.
            </p>
            <SmallPic
                image={require('../images/fb-interns.png')}
                caption="Product design interns on our last day!"
            />  
            <h2 className="ComingSoon">Please reach out if you’d like to learn more about my work.</h2>
            <Link className="BackHome" to="/">Back Home</Link>
        </div>
        </Fade>
        <Footer />
  </div>
      )
    }
  }
  
  export default Facebook;